import { variables } from 'theme/variables';
import { forEach, get, filter, includes } from 'lodash';
import { RfqMetaResult } from 'types/types.ts';

const {
  custom_blue: customBlue,
  custom_tag_grey: customTagGrey,
  custom_red: customRed,
  custom_green: customGreen,
  custom_orange: customOrange,
  custom_lime: customLime,
  custom_dark_blue: customDarkBlue,
  custom_teal: customTeal,
  custom_sky_blue: customSkyBlue,
  custom_yellow: customYellow,
} = variables;

export const conversationTypeMap = {
  internal: 1,
  external: 2,
  partner: 3,
};

export const userContactStatusColors = {
  Active: customGreen,
  Merged: customTagGrey,
  Removed: customRed,
  'Not at Company': customRed,
};

export const campaignTypeColors = {
  Standard: customBlue,
  Matching: customGreen,
};

export const campaignMatchTypeColors = {
  Attribute: customBlue,
  Demand: customLime,
  Supply: customTeal,
};

export const campaignColors = {
  Clicked: customGreen,
  Opened: customBlue,
  Delivered: customTeal,
  Sent: customTagGrey,
  Spammed: customRed,
  Unsubscribed: customRed,
};

export const userStatusColors = {
  Facilitator: customTagGrey,
  Registered: customGreen,
  'Pending Moderation': customOrange,
  'Pending Email Confirmation': customOrange,
  Prospect: customBlue,
  Inactive: customRed,
};

export const contentTypeColorMap = {
  supplyopportunity: customGreen,
  company: customOrange,
  authuser: customRed,
  rfq: customBlue,
};

export const opportunityTypeColorMap = {
  supply: customSkyBlue,
  rfq: customBlue,
};

export const opportunityStatusColors = {
  new: customBlue,
  not_quoted: customBlue,
  quoted: customDarkBlue,
  ordered: customTagGrey,
  offered: customTagGrey,
  shipped: customLime,
  closed_order: customGreen,
  delayed: customOrange,
  declined: customRed,
  deleted: customRed,
  dead: customRed,
  un_submitted: customRed,
  intelligence: customTeal,
};

export const opportunityItemStatusColors = {
  new: customBlue,
  not_quoted: customBlue,
  inquired: customBlue,
  quoted: customDarkBlue,
  ordered: customTagGrey,
  offered: customTagGrey,
  shipped: customLime,
  closed_order: customGreen,
  delayed: customOrange,
  declined: customRed,
  deleted: customRed,
  dead: customRed,
  un_submitted: customRed,
  intelligence: customTeal,
};

export const supplyStageColors = {
  finder_lead: customRed,
  internal_lead: customOrange,
  opportunity: customBlue,
  offer: customGreen,
  acquired: customTeal,
  market_inventory: customDarkBlue,
  archive: customTagGrey,
};

export const supplyStatusColors = {
  new: customBlue,
  offered: customBlue,
  exclusive: customDarkBlue,
  consign: customDarkBlue,
  purchased: customDarkBlue,
  delayed: customOrange,
  declined: customRed,
  deleted: customRed,
  dead: customRed,
  un_submitted: customRed,
  intelligence: customTeal,
};

export const supplyItemConditionStatusColors = {
  New: customGreen,
  Unused: customBlue,
  Used: customOrange,
};

export const statusColors = {
  Inquired: customBlue,
  Active: customBlue,
  Sent: customGreen,
  Acquired: customDarkBlue,
  Merged: customOrange,
  Removed: customRed,
  Dissolved: customRed,
};

export const miscStatusColors = {
  inquired: customBlue,
};

export const materialUsageColors = {
  revert: customOrange,
  scrap: customRed,
  'Resize/Remanufacture': customLime,
  'As-Is (Processing)': customTeal,
  'As-Is': customDarkBlue,
  mixed: customTagGrey,
};

export const priorityColorMap = {
  Highest: customRed,
  High: customOrange,
  Medium: null,
  Low: customLime,
  Lowest: customGreen,
  Legacy: customTagGrey,
};

export const interactionColorMap = {
  Meeting: customBlue,
  Email: customTeal,
  Call: customGreen,
};

export const actionItemColorMap = {
  Blocked: customRed,
  'Not Started': null,
  'In Progress': customGreen,
  Done: customDarkBlue,
};

export const campaignResultColorMap = {
  clicked: customBlue,
  opened: customGreen,
  delivered: customTeal,
  sent: customOrange,
  bounced: customRed,
  dropped: customDarkBlue,
};

export const productCategoryColorMap = {
  OCTG: customBlue,
  'Mechanical Tube / Coupling Stock': customGreen,
};

export const alloyFamilyColorMap = {
  'Duplex Stainless': customBlue,
  'Martensitic Stainless': customLime,
  'Nickel Alloy': customYellow,
  'PH Nickel': customGreen,
  Carbon: customOrange,
};

export const marketComparableTypeColorMap = {
  Stock: customBlue,
  Mill: customGreen,
  Auction: customOrange,
};

export const marketComparableIntelStatusColorMap = {
  Active: customLime,
  Removed: customRed,
};

export const facetLabelOverrides = {
  actionitem_status: 'Action Item Status',
  quantity_uom: 'Quantity Range',
};

export const customerFacetMap = {
  tag: {
    name: 'tag',
  },
  tag_missing: {
    name: 'tag',
    missing: true,
  },
  status: {
    name: 'status',
  },
  status_missing: {
    name: 'status',
    missing: true,
  },
  contact_status: {
    name: 'contact_status',
  },
  contact_status_missing: {
    name: 'contact_status',
    missing: true,
  },
  company: {
    name: 'company',
  },
  company_missing: {
    name: 'company',
    missing: true,
  },
  company_type: {
    name: 'company_type',
  },
  company_type_missing: {
    name: 'company_type',
    missing: true,
  },
  job_function: {
    name: 'job_function',
  },
  job_function_missing: {
    name: 'job_function',
    missing: true,
  },
  account_type: {
    name: 'account_type',
  },
  account_type_missing: {
    name: 'account_type',
    missing: true,
  },
  attribute_values: {
    name: 'attribute_values',
  },
};

export const customerAgGridFacetMap = {
  status: {
    facet: 'type',
  },
  contact_status: {
    facet: 'status',
  },
};

export const customersNetworkingAgGridFacetMap = {};

export const rfqStatusMap = [
  // {value: 'un-submitted', label: 'Un-submitted'}, Can not transition to un-submitted
  { value: 'new', label: 'New' },
  { value: 'not-quoted', label: 'Not Quoted' },
  { value: 'quoted', label: 'Quoted' },
  { value: 'ordered', label: 'Ordered' },
  { value: 'shipped', label: 'Shipped' },
  { value: 'closed_order', label: 'Closed Order' },
  { value: 'delayed', label: 'Delayed' },
  { value: 'declined', label: 'Declined' },
  { value: 'deleted', label: 'Deleted' },
  { value: 'dead', label: 'Dead' },
  { value: 'intelligence', label: 'Intelligence' },
];

export const rfqItemStatusMap = [
  // {value: 'un-submitted', label: 'Un-submitted'}, Can not transition to un-submitted
  { value: 'inquired', label: 'Inquired' },
  { value: 'declined', label: 'Declined' },
  { value: 'quoted', label: 'Quoted' },
  { value: 'ordered', label: 'Ordered' },
  { value: 'delayed', label: 'Delayed' },
  { value: 'dead', label: 'Dead' },
  { value: 'cancelled', label: 'Cancelled' },
];

export const rfqQuoteItemStatusMap = [
  { value: 'declined', label: 'Declined' },
  { value: 'quoted', label: 'Quoted' },
  { value: 'ordered', label: 'Ordered' },
  { value: 'delayed', label: 'Delayed' },
];

const defaultAllowedTransitions = ['market_inventory', 'archive'];

export const supplyStageMap = [
  {
    value: 'finder_lead',
    label: 'Finder Lead',
    allowedTransitions: [...defaultAllowedTransitions, 'opportunity'],
  },
  {
    value: 'internal_lead',
    label: 'Internal Lead',
    allowedTransitions: [...defaultAllowedTransitions, 'opportunity'],
  },
  {
    value: 'opportunity',
    label: 'Opportunity',
    allowedTransitions: [...defaultAllowedTransitions, 'offer'],
  },
  {
    value: 'offer',
    label: 'Offer',
    allowedTransitions: [...defaultAllowedTransitions, 'acquired'],
  },
  {
    value: 'acquired',
    label: 'Acquired',
    allowedTransitions: defaultAllowedTransitions,
  },
  {
    value: 'market_inventory',
    label: 'Market Inventory',
    allowedTransitions: [
      ...defaultAllowedTransitions,
      'opportunity',
      'internal_lead',
    ],
  },
  {
    value: 'archive',
    label: 'Archive',
    allowedTransitions: [
      ...defaultAllowedTransitions,
      'opportunity',
      'internal_lead',
    ],
  },
];

const supplyLeadStatusList = [
  {
    value: 'new',
    label: 'New',
  },
  {
    value: 'in_progress',
    label: 'In Progress',
  },
  {
    value: 'pending_review',
    label: 'Pending Review',
  },
  {
    value: 'needs_information',
    label: 'Needs Information',
  },
  {
    value: 'duplicate',
    label: 'Duplicate',
  },
  {
    // Some legacy Supply may have this status
    value: 'un_submitted',
    label: 'Un-Submitted',
  },
  {
    // Some legacy Supply may have this status
    value: 'lead',
    label: 'Lead',
  },
];

export const supplyStatusMap = {
  finder_lead: supplyLeadStatusList,
  internal_lead: supplyLeadStatusList,
  opportunity: [
    {
      value: 'analysis',
      label: 'Analysis',
    },
    {
      value: 'dormant',
      label: 'Dormant',
    },
    {
      value: 'delayed',
      label: 'Delayed',
    },
    {
      value: 'preparing_offer',
      label: 'Peparing Offer',
    },
  ],
  offer: [
    {
      value: 'delayed',
      label: 'Delayed',
    },
    {
      value: 'submitted',
      label: 'Submitted',
    },
    {
      value: 'rejected',
      label: 'Rejected',
    },
    {
      value: 'accepted',
      label: 'Accepted',
    },
    {
      value: 'expired',
      label: 'Expired',
    },
  ],
  acquired: [
    {
      value: 'ordered',
      label: 'Ordered',
    },
    {
      value: 'cancelled',
      label: 'Cancelled',
    },
    {
      value: 'expired',
      label: 'Expired',
    },
  ],
  market_inventory: [
    {
      value: 'no_longer_for_sale',
      label: 'No Longer For Sale',
    },
    {
      value: 'potentially_available',
      label: 'Potentially Available',
    },
    {
      value: 'sold',
      label: 'Sold',
    },
  ],
  archive: [
    {
      value: 'deleted',
      label: 'Deleted',
    },
  ],
};

export const listingStatusMap = [
  // {value: 'un-submitted', label: 'Un-submitted'}, Can not transition to un-submitted
  { value: 'un_submitted', label: 'Un Submitted' },
  { value: 'active', label: 'Active' },
  { value: 'sold', label: 'Sold' },
  { value: 'expired', label: 'Expired' },
  { value: 'inactive', label: 'Inactive' },
  { value: 'deleted', label: 'Deleted' },
];

export const listingStatusColors = {
  un_submitted: customRed,
  active: customBlue,
  sold: customLime,
  expired: customOrange,
  inactive: customRed,
  deleted: customRed,
};

export const contentStatusMap = [
  { value: 'un_published', label: 'Un Published' },
  { value: 'published', label: 'Published' },
  { value: 'deleted', label: 'Deleted' },
];

export const contentStatusColors = {
  un_published: customRed,
  active: customBlue,
  published: customBlue,
  deleted: customRed,
};

export const prospectingSearchTypeColors = {
  user: customBlue,
  company: customTeal,
};

export const deadStatusReason = [
  { label: 'lost because price', value: 'price' },
  { label: 'lost because delivery', value: 'delivery' },
  { label: 'requirement cancelled', value: 'cancelled' },
  { label: 'specification not aligned', value: 'alignment' },
];

export const elementsToAbbrev = {
  carbon: 'C',
  cobalt: 'Co',
  chromium: 'Cr',
  nickel: 'Ni',
  molybdenum: 'Mo',
  copper: 'Cu',
  titanium: 'Ti',
  vanadium: 'V',
  tungsten: 'W',
  nitrogen: 'N',
  aluminum: 'Al',
  silicon: 'Si',
  iron: 'Fe',
  manganese: 'Mn',
  tantalum: 'Ta',
  niobium: 'Nb',
  phosphorus: 'P',
  sulfur: 'S',
};

export const elementNumbers = {
  carbon: 6,
  cobalt: 27,
  chromium: 24,
  nickel: 28,
  molybdenum: 42,
  copper: 29,
  titanium: 22,
  vanadium: 23,
  tungsten: 74,
  nitrogen: 7,
  aluminum: 13,
  silicon: 14,
  iron: 26,
  manganese: 25,
  tantalum: 73,
  niobium: 41,
  phosphorus: 15,
  sulfur: 16,
};

export const userActiveStatus = {
  active: 1,
  removed: 2,
  merged: 3,
};

export const companyActiveStatus = {
  Active: 1,
  Removed: 2,
  Dissolved: 3,
  Acquired: 4,
  Merged: 5,
};

export const orderItemActiveStatus = {
  Active: 1,
  Removed: 2,
};

export const orderItemOutcomeDefault = {
  RFQ: {
    id: 1,
    name: 'Sell',
  },
  supplyGroup: {
    id: 2,
    name: 'Purchase',
  },
};

export const fileTypeMap = {
  default: {
    default: 1,
    orderSubType: {
      purchaseOrder: 1,
      spec: 2,
    },
    manufacturingOrderSubType: {
      purchaseOrder: 1,
      additional: 2,
    },
    supplyOrderSubType: {
      primary: 1,
      additional: 2,
    },
  },
  mtr: 2,
};

export const matchingByMap = [
  { option: { value: 'all', label: 'All', isBoolean: true } },
  { option: { value: 'attribute', label: 'Attribute' } },
  { option: { value: 'demand', label: 'Demand' } },
  { option: { value: 'supply', label: 'Supply' } },
];

export const relationshipStrengthMap = [
  { value: 1, label: 'Family' },
  { value: 2, label: 'Friend' },
  { value: 3, label: 'Strong' },
  { value: 4, label: 'Familiar' },
  { value: 5, label: 'Weak' },
  { value: 6, label: 'Distant' },
  { value: 7, label: 'Negative' },
];

export const customerEnrichmentFrequencyDays = 90;

export const prospectingSearchTypes = {
  users: 1,
  companies: 2,
};

export const prospectingSearchTypeItems = [
  { value: get(prospectingSearchTypes, 'users'), label: 'Users' },
  { value: get(prospectingSearchTypes, 'companies'), label: 'Companies' },
];

export const prospectingSearchTermsMap = {
  keyword: {
    key: 'key_word',
  },
  prospectingIndustry: {
    key: 'industry',
  },
  prospectingCompany: {
    key: 'company_name',
  },
  experienceLevel: {
    key: 'experience_level',
    sendLabel: true,
  },
  prospectingJobTitle: {
    key: 'job_title',
  },
  educationLevel: {
    key: 'education_level',
    sendLabel: true,
  },
  prospectingSchool: {
    key: 'school',
  },
  prospectingCompanySize: {
    key: 'company_size',
    sendLabel: true,
  },
  prospectingLocation: {
    paths: [
      { key: 'city', path: 'meta.locality' },
      { key: 'country', path: 'meta.country' },
    ],
  },
};

// https://docs.peopledatalabs.com/docs/job-title-levels
export const prospectingExperienceLevelItems = [
  { id: 1, name: 'CXO' },
  { id: 2, name: 'Director' },
  { id: 3, name: 'Entry' },
  { id: 4, name: 'Manager' },
  { id: 5, name: 'Owner' },
  { id: 6, name: 'Partner' },
  { id: 7, name: 'Senior' },
  { id: 8, name: 'Training' },
  { id: 9, name: 'Unpaid' },
  { id: 10, name: 'VP' },
];

// https://docs.peopledatalabs.com/docs/education-degrees
export const prospectingEducationLevelItems = [
  {
    id: 0,
    name: 'Associate Of Arts',
  },
  {
    id: 1,
    name: 'Associates',
  },
  {
    id: 2,
    name: 'Bachelor Of Aerospace Engineering',
  },
  {
    id: 3,
    name: 'Bachelor Of Applied Science',
  },
  {
    id: 4,
    name: 'Bachelor Of Architecture',
  },
  {
    id: 5,
    name: 'Bachelor Of Arts',
  },
  {
    id: 6,
    name: 'Bachelor Of Arts In Business Administration',
  },
  {
    id: 7,
    name: 'Bachelor Of Arts In Communication',
  },
  {
    id: 8,
    name: 'Bachelor Of Arts In Education',
  },
  {
    id: 9,
    name: 'Bachelor Of Biosystems Engineering',
  },
  {
    id: 10,
    name: 'Bachelor Of Business Administration',
  },
  {
    id: 11,
    name: 'Bachelor Of Chemical Engineering',
  },
  {
    id: 12,
    name: 'Bachelor Of Civil Engineering',
  },
  {
    id: 13,
    name: 'Bachelor Of Commerce',
  },
  {
    id: 14,
    name: 'Bachelor Of Design',
  },
  {
    id: 15,
    name: 'Bachelor Of Education',
  },
  {
    id: 16,
    name: 'Bachelor Of Electrical Engineering',
  },
  {
    id: 17,
    name: 'Bachelor Of Engineering',
  },
  {
    id: 18,
    name: 'Bachelor Of Fine Arts',
  },
  {
    id: 19,
    name: 'Bachelor Of General Studies',
  },
  {
    id: 20,
    name: 'Bachelor Of Industrial & Systems Engineering',
  },
  {
    id: 21,
    name: 'Bachelor Of Industrial Design',
  },
  {
    id: 22,
    name: 'Bachelor Of Interdisciplinary Studies',
  },
  {
    id: 23,
    name: 'Bachelor Of Interior Architecture',
  },
  {
    id: 24,
    name: 'Bachelor Of Law',
  },
  {
    id: 25,
    name: 'Bachelor Of Liberal Arts',
  },
  {
    id: 26,
    name: 'Bachelor Of Liberal Arts And Sciences',
  },
  {
    id: 27,
    name: 'Bachelor Of Materials Engineering',
  },
  {
    id: 28,
    name: 'Bachelor Of Mathematics',
  },
  {
    id: 29,
    name: 'Bachelor Of Mechanical Engineering',
  },
  {
    id: 30,
    name: 'Bachelor Of Medicine',
  },
  {
    id: 31,
    name: 'Bachelor Of Music',
  },
  {
    id: 32,
    name: 'Bachelor Of Music Education',
  },
  {
    id: 33,
    name: 'Bachelor Of Pharmacy',
  },
  {
    id: 34,
    name: 'Bachelor Of Professional Health Science',
  },
  {
    id: 35,
    name: 'Bachelor Of Science',
  },
  {
    id: 36,
    name: 'Bachelor Of Science In Aerospace Engineering',
  },
  {
    id: 37,
    name: 'Bachelor Of Science In Biomedical Engineering',
  },
  {
    id: 38,
    name: 'Bachelor Of Science In Business Administration',
  },
  {
    id: 39,
    name: 'Bachelor Of Science In Chemical Engineering',
  },
  {
    id: 40,
    name: 'Bachelor Of Science In Chemistry',
  },
  {
    id: 41,
    name: 'Bachelor Of Science In Civil Engineering',
  },
  {
    id: 42,
    name: 'Bachelor Of Science In Commerce Business Administration',
  },
  {
    id: 43,
    name: 'Bachelor Of Science In Computer Science',
  },
  {
    id: 44,
    name: 'Bachelor Of Science In Education',
  },
  {
    id: 45,
    name: 'Bachelor Of Science In Electrical Engineering',
  },
  {
    id: 46,
    name: 'Bachelor Of Science In Engineering',
  },
  {
    id: 47,
    name: 'Bachelor Of Science In Engineering Technology',
  },
  {
    id: 48,
    name: 'Bachelor Of Science In Geology',
  },
  {
    id: 49,
    name: 'Bachelor Of Science In Human Environmental Sciences',
  },
  {
    id: 50,
    name: 'Bachelor Of Science In Materials Engineering',
  },
  {
    id: 51,
    name: 'Bachelor Of Science In Mechanical Engineering',
  },
  {
    id: 52,
    name: 'Bachelor Of Science In Metallurgical Engineering',
  },
  {
    id: 53,
    name: 'Bachelor Of Science In Microbiology',
  },
  {
    id: 54,
    name: 'Bachelor Of Science In Nursing',
  },
  {
    id: 55,
    name: 'Bachelor Of Science In Social Work',
  },
  {
    id: 56,
    name: 'Bachelor Of Social Work',
  },
  {
    id: 57,
    name: 'Bachelor Of Software Engineering',
  },
  {
    id: 58,
    name: 'Bachelor Of Technology',
  },
  {
    id: 59,
    name: 'Bachelor Of Textile Engineering',
  },
  {
    id: 60,
    name: 'Bachelor Of Textile Management And Technology',
  },
  {
    id: 61,
    name: 'Bachelor Of Veterinary Science',
  },
  {
    id: 62,
    name: 'Bachelor Of Wireless Engineering',
  },
  {
    id: 63,
    name: 'Bachelors',
  },
  {
    id: 64,
    name: 'Doctor Of Audiology',
  },
  {
    id: 65,
    name: 'Doctor Of Business Administration',
  },
  {
    id: 66,
    name: 'Doctor Of Chiropractic',
  },
  {
    id: 67,
    name: 'Doctor Of Dental Surgery',
  },
  {
    id: 68,
    name: 'Doctor Of Education',
  },
  {
    id: 69,
    name: 'Doctor Of Jurisprudence',
  },
  {
    id: 70,
    name: 'Doctor Of Medical Dentistry',
  },
  {
    id: 71,
    name: 'Doctor Of Medicine',
  },
  {
    id: 72,
    name: 'Doctor Of Ministry',
  },
  {
    id: 73,
    name: 'Doctor Of Musical Arts',
  },
  {
    id: 74,
    name: 'Doctor Of Nursing Practice',
  },
  {
    id: 75,
    name: 'Doctor Of Optometry',
  },
  {
    id: 76,
    name: 'Doctor Of Osteophathy',
  },
  {
    id: 77,
    name: 'Doctor Of Pharmacy',
  },
  {
    id: 78,
    name: 'Doctor Of Philosophy',
  },
  {
    id: 79,
    name: 'Doctor Of Physical Therapy',
  },
  {
    id: 80,
    name: 'Doctor Of Psychology',
  },
  {
    id: 81,
    name: 'Doctor Of Public Health',
  },
  {
    id: 82,
    name: 'Doctor Of Science',
  },
  {
    id: 83,
    name: 'Doctor Of Veterinary Medicine',
  },
  {
    id: 84,
    name: 'Doctorates',
  },
  {
    id: 85,
    name: 'Magister Juris',
  },
  {
    id: 86,
    name: 'Magisters',
  },
  {
    id: 87,
    name: 'Master Of Accountancy',
  },
  {
    id: 88,
    name: 'Master Of Accounting',
  },
  {
    id: 89,
    name: 'Master Of Aerospace Engineering',
  },
  {
    id: 90,
    name: 'Master Of Agriculture',
  },
  {
    id: 91,
    name: 'Master Of Applied Mathematics',
  },
  {
    id: 92,
    name: 'Master Of Aquaculture',
  },
  {
    id: 93,
    name: 'Master Of Arts',
  },
  {
    id: 94,
    name: 'Master Of Arts In Education',
  },
  {
    id: 95,
    name: 'Master Of Arts In Teaching',
  },
  {
    id: 96,
    name: 'Master Of Building Construction',
  },
  {
    id: 97,
    name: 'Master Of Business Administration',
  },
  {
    id: 98,
    name: 'Master Of Chemical Engineering',
  },
  {
    id: 99,
    name: 'Master Of Civil Engineering',
  },
  {
    id: 100,
    name: 'Master Of Commerce',
  },
  {
    id: 101,
    name: 'Master Of Communication Disorders',
  },
  {
    id: 102,
    name: 'Master Of Dental Surgery',
  },
  {
    id: 103,
    name: 'Master Of Design',
  },
  {
    id: 104,
    name: 'Master Of Divinity',
  },
  {
    id: 105,
    name: 'Master Of Education',
  },
  {
    id: 106,
    name: 'Master Of Electrical Engineering',
  },
  {
    id: 107,
    name: 'Master Of Engineering',
  },
  {
    id: 108,
    name: 'Master Of Fine Arts',
  },
  {
    id: 109,
    name: 'Master Of Health Science',
  },
  {
    id: 110,
    name: 'Master Of Industrial Design',
  },
  {
    id: 111,
    name: 'Master Of Integrated Design And Construction',
  },
  {
    id: 112,
    name: 'Master Of International Studies',
  },
  {
    id: 113,
    name: 'Master Of Landscape Architecture',
  },
  {
    id: 114,
    name: 'Master Of Laws',
  },
  {
    id: 115,
    name: 'Master Of Liberal Arts',
  },
  {
    id: 116,
    name: 'Master Of Library & Information Studies',
  },
  {
    id: 117,
    name: 'Master Of Library Science',
  },
  {
    id: 118,
    name: 'Master Of Materials Engineering',
  },
  {
    id: 119,
    name: 'Master Of Mechanical Engineering',
  },
  {
    id: 120,
    name: 'Master Of Music',
  },
  {
    id: 121,
    name: 'Master Of Natural Resources',
  },
  {
    id: 122,
    name: 'Master Of Nurse Anesthesia',
  },
  {
    id: 123,
    name: 'Master Of Political Science',
  },
  {
    id: 124,
    name: 'Master Of Professional Studies',
  },
  {
    id: 125,
    name: 'Master Of Public Administration',
  },
  {
    id: 126,
    name: 'Master Of Public Health',
  },
  {
    id: 127,
    name: 'Master Of Real Estate Development',
  },
  {
    id: 128,
    name: 'Master Of Rehabilitation Counseling',
  },
  {
    id: 129,
    name: 'Master Of Science',
  },
  {
    id: 130,
    name: 'Master Of Science In Aerospace Engineering',
  },
  {
    id: 131,
    name: 'Master Of Science In Basic Medical Sciences',
  },
  {
    id: 132,
    name: 'Master Of Science In Biomedical Engineering',
  },
  {
    id: 133,
    name: 'Master Of Science In Chemical Engineering',
  },
  {
    id: 134,
    name: 'Master Of Science In Chemistry',
  },
  {
    id: 135,
    name: 'Master Of Science In Civil Engineering',
  },
  {
    id: 136,
    name: 'Master Of Science In Computer Science',
  },
  {
    id: 137,
    name: 'Master Of Science In Criminal Justice',
  },
  {
    id: 138,
    name: 'Master Of Science In Education',
  },
  {
    id: 139,
    name: 'Master Of Science In Electrical Engineering',
  },
  {
    id: 140,
    name: 'Master Of Science In Engineering Science & Mechanics',
  },
  {
    id: 141,
    name: 'Master Of Science In Forensic Science',
  },
  {
    id: 142,
    name: 'Master Of Science In Health Administration',
  },
  {
    id: 143,
    name: 'Master Of Science In Health Informatics',
  },
  {
    id: 144,
    name: 'Master Of Science In Human Environmental Sciences',
  },
  {
    id: 145,
    name: 'Master Of Science In Industrial Engineering',
  },
  {
    id: 146,
    name: 'Master Of Science In Information Systems',
  },
  {
    id: 147,
    name: 'Master Of Science In Instructional Leadership Administration',
  },
  {
    id: 148,
    name: 'Master Of Science In Justice And Public Safety',
  },
  {
    id: 149,
    name: 'Master Of Science In Materials Engineering',
  },
  {
    id: 150,
    name: 'Master Of Science In Mechanical Engineering',
  },
  {
    id: 151,
    name: 'Master Of Science In Metallurgical Engineering',
  },
  {
    id: 152,
    name: 'Master Of Science In Nursing',
  },
  {
    id: 153,
    name: 'Master Of Science In Occupational Therapy',
  },
  {
    id: 154,
    name: 'Master Of Science In Operations Research',
  },
  {
    id: 155,
    name: 'Master Of Science In Physician Assistant Studies',
  },
  {
    id: 156,
    name: 'Master Of Science In Public Health',
  },
  {
    id: 157,
    name: 'Master Of Science In Software Engineering',
  },
  {
    id: 158,
    name: 'Master Of Social Work',
  },
  {
    id: 159,
    name: 'Master Of Software Engineering',
  },
  {
    id: 160,
    name: 'Master Of Tax Accounting',
  },
  {
    id: 161,
    name: 'Master Of Taxation',
  },
  {
    id: 162,
    name: 'Master Of Technical & Professional Communication',
  },
  {
    id: 163,
    name: 'Master Of Technology',
  },
  {
    id: 164,
    name: 'Master Of Urban And Regional Planning',
  },
  {
    id: 165,
    name: 'Masters',
  },
];

// https://docs.peopledatalabs.com/docs/company-sizes
export const prospectingCompanySizeItems = [
  { id: 1, name: '1-10' },
  { id: 2, name: '11-50' },
  { id: 3, name: '51-200' },
  { id: 4, name: '201-500' },
  { id: 5, name: '501-1000' },
  { id: 6, name: '1001-5000' },
  { id: 7, name: '5001-10000' },
  { id: 8, name: '10001+' },
];

export const enrichmentStages = {
  ingestProspects: 'ingestProspects',
  ingestUnknownCompanies: 'ingestUnknownCompanies',
  displaySuccess: 'displaySuccess',
};

// WARNING: Date objects are mutable
// To avoid mutating a date you need to reference with .setDate(), create a copy:
// const originalDateClone = new Date(originalDate)
// To increment a date by a certain number of days:
// new Date(originalDateClone.setDate(originalDateClone.getDate() + integerDays))
// To get the number of days since a date:
// Math.round((new Date() - originalDate) / dayForDateComparisons)
export const dayForDateComparisons = 1000 * 60 * 60 * 24;

export const rfqStatusDict: Record<string, string> = {};
forEach(rfqStatusMap, ({ value, label }) => {
  rfqStatusDict[value] = label;
});

export const weightUom = ['LB', 'KG', 'TON', 'TONNE'];

export const lengthUom = ['FT', 'IN', 'M'];

export function getWeightUom(metadata: RfqMetaResult) {
  return filter(get(metadata, 'quantity_uom'), (uom) =>
    includes(weightUom, uom.name)
  );
}

export function getLengthUom(metadata: RfqMetaResult) {
  return filter(get(metadata, 'quantity_uom'), (uom) =>
    includes(lengthUom, uom.name)
  );
}

export const getScalarUOM = (metadata: RfqMetaResult) =>
  filter(get(metadata, 'quantity_uom'), (uom) => get(uom, 'name') !== 'Unit');

export const getUnitUOM = (metadata: RfqMetaResult) =>
  filter(get(metadata, 'quantity_uom'), (uom) => get(uom, 'name') === 'Unit');

// catagory labels that use unitUOM instead of scalarUOM
export const unitLabels = [
  'Coupling',
  'Pup Joint',
  'Mechanical Tube/Coupling Stock',
];

export const countryAbbreviations = {
  'United States': 'USA',
  'United Arab Emirates': 'UAE',
  'Bosnia and Herzegovina': 'B&H',
};

export const companyFacetMap = {
  parent_id: {
    facet: 'parent',
    label: 'parent_name',
  },
  priority: {
    label: 'priority_name',
  },
  status: {
    label: 'status_name',
  },
  company_type: {
    label: 'company_type_name',
  },
};

export const marketComparablesFacetMap = {
  contact_id: {
    facet: 'contact',
    label: 'contact_name',
    sortName: 'contact_name',
  },
  employee_id: {
    facet: 'employee',
    label: 'employee_name',
    sortName: 'employee_name',
  },
  buyer_id: {
    facet: 'buyer',
    label: 'buyer',
    sortName: 'buyer_name',
  },
  end_user_id: {
    facet: 'end_user',
    label: 'end_user',
    sortName: 'end_user',
  },
  category: {
    label: 'category_name',
  },
  type: {
    label: 'type_display',
  },
  metallurgy_group: {
    label: 'metallurgy_group_name',
    sortName: 'metallurgy_group_name',
  },
  manufacturer: {
    label: 'manufacturer_name',
    sortName: 'manufacturer_name',
  },
  mtr: {
    buckets: {
      filterLabels: {
        true: 'Yes',
        false: 'No',
      },
    },
  },
  intel_status: {
    label: 'intel_status_display',
  },
  received_date: {
    sortName: 'received_date_dt',
  },
};

export const purchaseHistoryGroupFacetMap = {
  vendor_id: {
    facet: 'vendor',
    label: 'vendor_name',
    sortName: 'vendor_name',
  },
};

export const purchaseHistoryItemFacetMap = {
  vendor_id: {
    facet: 'vendor',
    label: 'vendor_name',
    sortName: 'vendor_name',
  },
  category: {
    buckets: {
      cellValues: {
        'Mechanical Tube/Coupling Stock': 'Mechanical',
        'Miscellaneous Pilger Parts and Repair': 'Misc. Pilger/Repair',
      },
      groupBucketValues: {
        Mechanical: 'Mechanical Tube/Coupling Stock',
        'Misc. Pilger/Repair': 'Miscellaneous Pilger Parts and Repair',
      },
    },
  },
};

export const orderHistoryGroupFacetMap = {
  customer_id: {
    facet: 'customer',
    label: 'customer_name',
    sortName: 'customer_name_s',
  },
};

export const orderHistoryItemFacetMap = {
  customer_id: {
    facet: 'customer',
    label: 'customer_name',
    sortName: 'customer_name',
  },
  category: {
    buckets: {
      cellValues: {
        'Mechanical Tube/Coupling Stock': 'Mechanical',
        'Miscellaneous Pilger Parts and Repair': 'Misc. Pilger/Repair',
      },
      groupBucketValues: {
        Mechanical: 'Mechanical Tube/Coupling Stock',
        'Misc. Pilger/Repair': 'Miscellaneous Pilger Parts and Repair',
      },
    },
  },
};

export const requestHistoryGroupFacetMap = {
  customer_id: {
    facet: 'customer',
    label: 'customer_name',
    sortName: 'customer_name_s',
  },
};

export const requestHistoryItemFacetMap = {
  customer_id: {
    facet: 'customer',
    label: 'customer_name',
    sortName: 'customer_name',
  },
  category: {
    buckets: {
      cellValues: {
        'Mechanical Tube/Coupling Stock': 'Mechanical',
        'Miscellaneous Pilger Parts and Repair': 'Misc. Pilger/Repair',
      },
      groupBucketValues: {
        Mechanical: 'Mechanical Tube/Coupling Stock',
        'Misc. Pilger/Repair': 'Miscellaneous Pilger Parts and Repair',
      },
    },
  },
};

export const demandGroupFacetMap = {
  priority: {
    label: 'priority_name',
    sortName: 'priority_rank',
  },
  status: {
    label: 'status_display',
    sortName: 'status_rank',
  },
  user: {
    label: 'user_name',
    sortName: 'user_name',
  },
  end_user_id: {
    sortName: 'end_user_name',
  },
  inquiring_company_id: {
    facet: 'company',
    label: 'inquiring_company_name',
    sortName: 'inquiring_company_name',
  },
  role_quote_lead: {
    facet: 'quote_lead',
    label: 'role_quote_lead_name',
    sortName: 'role_quote_lead_name',
  },
  role_owner: {
    facet: 'owner',
    label: 'role_owner_name',
    sortName: 'role_owner_name',
  },
  engagement_id: {
    facet: 'engagement',
    label: 'engagement_title',
    sortName: 'engagement_title',
  },
};

export const demandItemFacetMap = {
  priority: {
    facet: 'priority',
    label: 'priority_name',
    sortName: 'priority_rank',
  },
  primary_contact: {
    facet: 'primary_contact',
    label: 'primary_contact_name',
    sortName: 'primary_contact',
  },
};

export const demandCriteriaMap = {};

export const supplyGroupFacetMap = {
  engagement_id: {
    facet: 'engagement',
    label: 'engagement_title',
    sortName: 'engagement_title',
  },
  company_contact_id: {
    facet: 'finding_company',
    label: 'company_contact_name',
    sortName: 'finding_company',
  },
  owner_id: {
    facet: 'owner',
    label: 'owner_name',
    sortName: 'owner',
  },
  priority: {
    label: 'priority_name',
    sortName: 'priority_rank',
  },
  stage: {
    label: 'stage_name',
  },
  status: {
    label: 'status_name',
    sortName: 'status_rank',
  },
  supply_item_location: {
    facet: 'supply_location_country',
  },
  lead_source: {
    label: 'lead_source',
    sortName: 'lead_source_display',
  },
};

export const supplyItemFacetMap = {
  finding_company: {
    label: 'finding_company_name',
  },
  owner: {
    label: 'owner_name',
  },
  priority: {
    label: 'priority_name',
    sortName: 'priority_rank',
  },
  status: {
    label: 'status_name',
    sortName: 'status_rank',
  },
  supply_opportunity_name: {
    sortName: 'supply_opportunity',
  },
};

export const supplyCriteriaMap = {};

export const marketComparablesCriteriaMap = {
  od: {
    min: 'od_min',
    max: 'od_max',
  },
  weight_per_foot: {
    min: 'weight_per_foot_min',
    max: 'weight_per_foot_max',
  },
  min_yield: {
    min: 'min_yield_min',
    max: 'min_yield_max',
  },
};

export const purchaseItemHistoryCriteriaMap = {
  od: {
    min: 'od_min',
    max: 'od_max',
  },
  weight_per_foot: {
    min: 'weight_per_foot_min',
    max: 'weight_per_foot_max',
  },
  min_yield: {
    min: 'min_yield_min',
    max: 'min_yield_max',
  },
};

export const orderItemHistoryCriteriaMap = {
  od: {
    min: 'od_min',
    max: 'od_max',
  },
  weight_per_foot: {
    min: 'weight_per_foot_min',
    max: 'weight_per_foot_max',
  },
  min_yield: {
    min: 'min_yield_min',
    max: 'min_yield_max',
  },
};

export const requestItemHistoryCriteriaMap = {
  od: {
    min: 'od_min',
    max: 'od_max',
  },
  weight_per_foot: {
    min: 'weight_per_foot_min',
    max: 'weight_per_foot_max',
  },
  min_yield: {
    min: 'min_yield_min',
    max: 'min_yield_max',
  },
};

export const requestHistoryStatusMap = {
  not_quoted: 'Not Quoted',
  un_submitted: 'Unsubmitted',
  closed_order: 'Closed Order',
};

export const userRoles = [
  {
    value: 'admin',
    label: 'Administrator',
    sessionRole: 'admin',
    rank: 1,
  },
  {
    value: 'facilitator_executive',
    label: 'Executive',
    sessionRole: 'facilitatorexecutive',
    rank: 2,
  },
  {
    value: 'facilitator_manager',
    label: 'Manager',
    sessionRole: 'facilitatormanager',
    rank: 3,
  },
  {
    value: 'facilitator_partner',
    label: 'Partner',
    sessionRole: 'facilitatorpartner',
    rank: 4,
  },
  {
    value: 'facilitator_operator',
    label: 'Operator',
    sessionRole: 'facilitatoroperator',
    rank: 5,
  },
];

export const attributeMap = [
  {
    label: 'Corrosion Resistance',
    key: 'corrosion_resistance_name',
  },
  {
    label: 'Alloy Group',
    key: 'metallurgy_group_name',
  },
  {
    label: 'Yield Strength',
    isRange: true,
    minKey: 'yield_strength_min',
    maxKey: 'yield_strength_max',
  },
  {
    label: 'Wall Tolerance',
    key: 'wall_tollerance', // server-side misspelling
  },
  {
    label: 'Density',
    key: 'density',
  },
  {
    label: 'PREN',
    isRange: true,
    minKey: 'pren_min',
    maxKey: 'pren_max',
  },
];

export const contactExpertMap = [
  'Commercial Representative',
  'Engineering',
  'Metallurgist',
  'Well Analysis',
  'Drilling & Completions',
  'Just-in-Time (JIT) Manufacturing',
  'Accessory Equipment Manufacturing',
  'Special Projects',
  'PipeSearch Tech Support',
  `I don't know!`,
];

export const alloySynonymMap = {
  2050: {
    name: '2050',
    synonyms: ['SM2050'],
  },
  C276: {
    name: 'C276',
    synonyms: ['N10276', 'W. Nr. 2.4819', 'SMC276', 'Hastelloy C276'],
  },
  625: {
    name: '625',
    synonyms: ['Inconel 625', 'W.Nr. 2.4856', 'Inc625'],
  },
  C22: {
    name: 'C22',
    synonyms: ['Alloy 22', 'Hastelloy 22', 'C22 HS', 'N06022'],
  },
  G50: {
    name: 'G50',
    synonyms: ['Alloy 50', 'N06950'],
  },
  '2550E': {
    name: '2550E',
  },
  2550: {
    name: '2550',
    synonyms: [
      'SM2550',
      'DMV2550',
      '2250',
      'SM2250',
      '2050',
      'SM2050',
      '2550E',
      '25%CR50%Ni',
      'CRA2550E',
      'CRA2550',
      'M2550',
    ],
  },
  G3: {
    name: 'G3',
    synonyms: ['Inconel G3', 'W. Nr. 2.4619'],
  },
  G2: {
    name: 'G2',
  },
  2242: {
    name: '2242',
    synonyms: ['SM2242', 'W.Nr. 2.4858'],
  },
  825: {
    name: '825',
    synonyms: ['Inconel 825', 'Inc825', 'Alloy 825'],
  },
  2535: {
    name: '2535',
    synonyms: ['SM2535', '25 CR35 Ni'],
  },
  '28CR': {
    name: '28 Chrome',
    synonyms: [
      '28CR',
      '2832',
      '928',
      'Nic28',
      'Sanicro 28',
      '1.4563',
      'X1NiCrMo31-27-4',
      'VM28',
      'SAF 28',
      'Alloy 28',
    ],
  },
  '25CRW': {
    name: '25CRW',
    synonyms: ['SM25CRW', 'Super 25CrW'],
  },
  '25CRS': {
    name: 'Super Duplex 25 Chrome',
    synonyms: [
      '25CRS',
      'F61',
      'Ferralium 255',
      'F53',
      'F55',
      'S32760',
      'S32750',
      'SAF 2507',
      '1.4501',
      '1.441',
      '1.4507',
      'S39277',
      'AF 918',
      'VM25S',
      'Z100',
      '918',
      'Super 25 Chrome',
    ],
  },
  '25CR': {
    name: '25 Chrome',
    synonyms: ['25CR', '2507', 'VM25'],
  },
  '22CR': {
    name: '22 Chrome / 2205',
    synonyms: [
      '22CR',
      '2205',
      'Sanmac 22Cr',
      'Duplex 2205',
      'Duplex F60',
      'Duplex F51',
      '318S13',
      '1.4462',
      'VM22',
    ],
  },
  '17CR': {
    name: '17 Chrome',
    synonyms: ['17CR', '17CRS', 'Super 17Cr'],
  },
  '15CR': {
    name: '15 Chrome',
    synonyms: ['15CR', 'UHP-15CR', 'Ultra Hyper Chrome', 'UHP', '15CR UHP'],
  },
  '13CRS': {
    name: 'Super 13 Chrome',
    synonyms: [
      '13CRS',
      'S13Cr',
      'AF 913',
      'VM 95 13CrSS',
      'SM13CRS',
      'Super 13Cr',
    ],
  },
  '13CR-HP2': {
    name: '13CR-HP2',
    synonyms: ['HP2', 'Hyper II'],
  },
  '13CRM': {
    name: 'Modified 13 Chrome',
    synonyms: ['SM13CRM', '13CRM', 'HP1-13CR'],
  },
  '13CR-HP1': {
    name: '13CR-HP1',
    synonyms: ['HP1', 'Hyper I', 'HP1-13CR'],
  },
  '13CR': {
    name: '13 Chrome',
    synonyms: [
      '13CR',
      '420',
      '420 Mod',
      '13CR L80',
      'VM 80 13CR',
      'SM13CR',
      'Chrome 13',
      'L13',
      '420M',
      'TN13CR',
      'TN13',
      'L80 13CR',
      'VM80 13CR',
      '13CR-L80',
      '13%Cr',
      '13% CR',
      'API L80 13CR',
    ],
  },
  '29CR': {
    name: '29CR',
    synonyms: ['Sanicro29', 'SA29'],
  },
};

/*
  The version of downshift installed by Zendesk Garden Dropdowns contains this code:
  node_modules > downshift > preact > dist > downshift.cjs.js

  var unknown = process.env.NODE_ENV !== "production" ? '__autocomplete_unknown__' : 0;
  var mouseUp = process.env.NODE_ENV !== "production" ? '__autocomplete_mouseup__' : 1;
  var itemMouseEnter = process.env.NODE_ENV !== "production" ? '__autocomplete_item_mouseenter__' : 2;
  var keyDownArrowUp = process.env.NODE_ENV !== "production" ? '__autocomplete_keydown_arrow_up__' : 3;
  var keyDownArrowDown = process.env.NODE_ENV !== "production" ? '__autocomplete_keydown_arrow_down__' : 4;
  var keyDownEscape = process.env.NODE_ENV !== "production" ? '__autocomplete_keydown_escape__' : 5;
  var keyDownEnter = process.env.NODE_ENV !== "production" ? '__autocomplete_keydown_enter__' : 6;
  var keyDownHome = process.env.NODE_ENV !== "production" ? '__autocomplete_keydown_home__' : 7;
  var keyDownEnd = process.env.NODE_ENV !== "production" ? '__autocomplete_keydown_end__' : 8;
  var clickItem = process.env.NODE_ENV !== "production" ? '__autocomplete_click_item__' : 9;
  var blurInput = process.env.NODE_ENV !== "production" ? '__autocomplete_blur_input__' : 10;
  var changeInput = process.env.NODE_ENV !== "production" ? '__autocomplete_change_input__' : 11;
  var keyDownSpaceButton = process.env.NODE_ENV !== "production" ? '__autocomplete_keydown_space_button__' : 12;
  var clickButton = process.env.NODE_ENV !== "production" ? '__autocomplete_click_button__' : 13;
  var blurButton = process.env.NODE_ENV !== "production" ? '__autocomplete_blur_button__' : 14;
  var controlledPropUpdatedSelectedItem = process.env.NODE_ENV !== "production" ? '__autocomplete_controlled_prop_updated_selected_item__' : 15;
  var touchEnd = process.env.NODE_ENV !== "production" ? '__autocomplete_touchend__' : 16;

  These string values become Numbers in a Production environment - here we can just check the array for either value
*/
export const downshiftAutocompleteChangeTypes = {
  unknown: ['__autocomplete_unknown__', 0],
  mouseUp: ['__autocomplete_mouseup__', 1],
  itemMouseEnter: ['__autocomplete_item_mouseenter__', 2],
  keyDownArrowUp: ['__autocomplete_keydown_arrow_up__', 3],
  keyDownArrowDown: ['__autocomplete_keydown_arrow_down__', 4],
  keyDownEscape: ['__autocomplete_keydown_escape__', 5],
  keyDownEnter: ['__autocomplete_keydown_enter__', 6],
  keyDownHome: ['__autocomplete_keydown_home__', 7],
  keyDownEnd: ['__autocomplete_keydown_end__', 8],
  clickItem: ['__autocomplete_click_item__', 9],
  blurInput: ['__autocomplete_blur_input__', 10],
  changeInput: ['__autocomplete_change_input__', 11],
  keyDownSpaceButton: ['__autocomplete_keydown_space_button__', 12],
  clickButton: ['__autocomplete_click_button__', 13],
  blurButton: ['__autocomplete_blur_button__', 14],
  controlledPropUpdatedSelectedItem: [
    '__autocomplete_controlled_prop_updated_selected_item__',
    15,
  ],
  touchEnd: ['__autocomplete_touchend__', 16],
};

export const supplyOrderOutcomeList = [
  { title: 'Purchase', key: 1 },
  { title: 'Consignment', key: 2 },
  { title: 'Exclusive Marketing', key: 4 },
  { title: 'Joint Venture', key: 3 },
];

export const plurals = {
  foot: 'feet',
  inch: 'inches',
};

export const itemFiltersCriteria = [
  'od',
  'od_min',
  'od_max',
  'weight_per_foot',
  'weight_per_foot_min',
  'weight_per_foot_max',
  'metallurgy',
  'metallurgy_group',
  'min_yield',
  'min_yield_min',
  'min_yield_max',
  'end_finish',
];

export const priorityNumberMap = {
  Highest: 1,
  High: 2,
  Medium: 3,
  Low: 4,
  Lowest: 5,
  Legacy: 6,
};

export const priorityStringMap = {
  Highest: '1',
  High: '2',
  Medium: '3',
  Low: '4',
  Lowest: '5',
  Legacy: '6',
};

type AssetManagementInventoryColumnMapValue = {
  name: string;
  arrayIndex?: number;
};

export const assetManagementInventoryColumnMap: Record<
  string,
  AssetManagementInventoryColumnMapValue
> = {
  category: {
    name: 'category',
  },
  od: {
    name: 'pipe_od',
  },
  od_min: {
    name: 'pipe_od',
    arrayIndex: 0,
  },
  od_max: {
    name: 'pipe_od',
    arrayIndex: 1,
  },
  inside_diameter: {
    name: 'pipe_id',
  },
  weight_per_foot: {
    name: 'pipe_wt_ft',
  },
  weight_per_foot_min: {
    name: 'pipe_wt_ft',
    arrayIndex: 0,
  },
  weight_per_foot_max: {
    name: 'pipe_wt_ft',
    arrayIndex: 1,
  },
  wall: {
    name: 'pipe_wall',
  },
  metallurgy: {
    name: 'pipe_alloy',
  },
  min_yield: {
    name: 'pipe_ys',
  },
  min_yield_min: {
    name: 'pipe_ys',
    arrayIndex: 0,
  },
  min_yield_max: {
    name: 'pipe_ys',
    arrayIndex: 1,
  },
  end_finish: {
    name: 'pipe_end_finish',
  },
  brand: {
    name: 'manufacturer_name',
  },
};

export const assetManagementAlloyMatchingMap = {
  '13CR': ['13CR'],
  '13CR-HP1': ['13CR-HP1', '13CRM'],
  '13CRM': ['13CR-HP1', '13CRM'],
  '13CR-HP2': ['13CR-HP2', '13CRS', '13CR-HP1', '13CRM'],
  '15CR': ['15CR', '13CR-HP2', '13CRS', '13CR-HP1', '13CRM'],
  '17CR': ['17CR', '15CR', '13CR-HP2', '13CRS', '25CRW'],
  '22CR': ['22CR', '17CR', '15CR'],
  '25CR': ['25CR', '22CR', '17CR', '15CR'],
  '25CRS': ['25CRW', '25CRS', '25CR', '22CR', '17CR'],
  '25CRW': ['25CRW', '25CRS', '25CR', '22CR', '17CR'],
  '28CR': ['28CR', '2535'],
  '2535': ['28CR', '2535'],
  '2242': ['2242', '825', '28CR', '2535'],
  '825': ['2242', '825', '28CR', '2535'],
  G3: ['G3', '2550', '2242', '825', '28CR', '2535'],
  '2550': ['G3', '2550', '2242', '825', '28CR', '2535'],
  '625': ['625', '725'],
  '725': ['625', '725'],
  C276: ['625', '725', 'C276', 'C22'],
  C22: ['625', '725', 'C276', 'C22'],
};
